import React from "react";

import logo from "../../assets/logo.svg";

const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3 app-logo" src={logo} alt="Bellgame Cat" width="120" />
  </div>
);

export default Hero;
